/* You can add global styles to this file, and also import other style files */

@import "~animate.css/animate.css";
body {
  background-color: #ddd;
  overflow: hidden;
}
@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/static/Montserrat-Regular.ttf") format("truetype"),
    url("assets/fonts/static/Montserrat-Bold.ttf") format("truetype");
}
